import {computed, inject, Injectable, signal} from '@angular/core';
import {TelegramAuthResponse} from "../telegram-widget/models/telegram-auth-response";
import {HttpClient} from "@angular/common/http";
import {lastValueFrom} from "rxjs";
import {ResponseWrapperWithContentDto} from "../../common/models/interfaces/response-wrapper";
import {ResponseStatusEnum} from "../../common/models/enums/response-status.enum";
import {ACCESS_TOKEN_KEY} from "../configs/auth.config";
import {JwtHelperService} from "@auth0/angular-jwt";
import {UserRole} from "../models/enums/user-role.enum";
import {SignInRequestDto} from "../models/dtos/sign-in-request";
import {SignInMethodEnum} from "../models/enums/sign-in-method.enum";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public readonly isAuthorized = computed(() => this.isAuthorizedSignal());
  private isAuthorizedSignal = signal<boolean | null>(null);
  private authUrl = '/api/Auth/';
  private http = inject(HttpClient);
  private jwtHelper = inject(JwtHelperService);
  private router = inject(Router);

  constructor() { }

  public async signInViaTelegram(user: TelegramAuthResponse, pretendedUserRole?: UserRole | null): Promise<void> {
    if (!!user) {
      const request: SignInRequestDto = {
        signInMethod: SignInMethodEnum.ByTelegram,
        pretendedUserRoleId: pretendedUserRole ?? UserRole.Trainee,
        data: user
      }
      const result = await lastValueFrom(this.http.post<ResponseWrapperWithContentDto<string>>(this.authUrl + 'sign-in', request));
      if (result?.status === ResponseStatusEnum.Success && result.content) {
        localStorage.setItem(ACCESS_TOKEN_KEY, result.content);
        this.isAuthorizedSignal.set(true);
      } else {
        localStorage.removeItem(ACCESS_TOKEN_KEY);
        this.isAuthorizedSignal.set(false);
        console.error(result.message);
      }
    } else {
      console.error('Нет данных пользователя Telegram');
    }
  }

  public async signOut(redirectTo?: string): Promise<void> {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    this.isAuthorizedSignal.set(false);
    if (!!redirectTo) {
      await this.router.navigateByUrl(redirectTo);
      window.location.reload();
    }
  }

  public validateToken(): void {
    const token = localStorage.getItem(ACCESS_TOKEN_KEY);
    if (!token || this.jwtHelper.isTokenExpired(token)) {
      this.signOut();
    } else {
      if (!this.isAuthorizedSignal()) {
        this.isAuthorizedSignal.set(true);
      }
    }
  }
}
